import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminApi } from "../../Services/AdminApi";



export const login = createAsyncThunk(
    "login",
    async(details)=> {
        const  data   = await AdminApi.post('/login', details);
        return data
    }
)