import { configureStore } from '@reduxjs/toolkit'
import  { usermanageSlice }  from '../redux/Reducer/UsermgmtSlice';



export const Store = configureStore({
    reducer: {
    usermgt:usermanageSlice.reducer,
        
    },
});