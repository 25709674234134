import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteUser,
  DowanloadUserxlsv,
  maangeuseraction,
  usermanagement,
} from "../redux/Actions/AdminActions";
import SearchBar from "../Common/Searchbar";
import { all } from "axios";
import * as XLSX from "xlsx";
import PaginationComponet from "../../src/Components/Layout/Pagination";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import Logo from "../Assets/Images/Placeholder.png";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function User() {
  const { alldata, loading, error, param } = useSelector(
    (state) => state.usermgt
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [showd, setshowd] = useState(false);
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);

  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [activePopoverId, setActivePopoverId] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [filterRole, setFilterRole] = useState("");

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      usermanagement({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: searchValue,
      type: filterRole,
    };
    dispatch(usermanagement(searchData));
  }, [page, limit, searchValue, flag, filterRole, dispatch]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadUserxlsv());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  const handleDeleteUSer = () => {
    dispatch(DeleteUser({ id: userid }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  const ActiveOrDeactivate = (userid, status) => {
    dispatch(maangeuseraction({ userId: userid, type: status }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setActivePopoverId(null);
          setflag(!flag);
        }
      })
      .catch((error) => {
        console.log(error, "Error occurred");
      });
  };

  const handleTogglePopover = (id) => {
    setActivePopoverId((prevId) => (prevId === id ? null : id));
  };

  const popover = (id) => {
    return (
      <Popover id="popover-basic" style={{ cursor: "pointer" }}>
        <Popover.Body>
          <p onClick={() => ActiveOrDeactivate(id, 1)}>Active</p>
          <p onClick={() => ActiveOrDeactivate(id, 0)}>In-Active</p>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">Total Users</span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Basic users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Standard users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Premium users</p>
                          <h2>00</h2>
                          {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <h2 className="filter">Filters</h2>
            <div className="category-field">
              <div className="input-select">
                <Form.Select aria-label="Default select example">
                  <option> Select Plan </option>
                  <option value="1">1 Month</option>
                  <option value="2">6 Month</option>
                  <option value="3">1 year</option>
                </Form.Select>
              </div>
              <div className="input-select">
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => setFilterRole(e.target.value)}
                >
                  <option> Select Status </option>
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">In-Active</option>
                </Form.Select>
              </div>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <SearchBar setSearchValue={setSearchValue} />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>
                      <div className="user-checkbox">
                        <div className="remember-check">
                          {/* <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          /> */}
                        </div>
                        User
                      </div>
                    </th>
                    <th>Phone no.</th>
                    <th>Plan</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {alldata?.data?.users?.map((res, index) => {
                    return (
                      <tr>
                        <td>
                          <div className="first-user">
                            <div className="remember-check">
                              {/* <input
                            type="checkbox"
                            class="red"
                            id="filled-in-box"
                          /> */}
                            </div>
                            <div className="user-profile">
                              <img
                                src={
                                  res?.profile_pic
                                    ? `${url}public/${res?.profile_image}`
                                    : Logo
                                }
                                style={{ borderRadius: 50 }}
                              />

                              <div className="user-id">
                                <p>{res?.name || "N/A"}</p>
                                <span>{res?.email || "N/A"}</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="cont-num">
                            {" "}
                            {res?.phone_no
                              ? res.country_code
                                ? `${res.country_code} ${res.phone_no}`
                                : `${res.phone_no}`
                              : "N/A"}{" "}
                          </div>
                        </td>
                        <td>
                          <div className="debit">Basic</div>
                        </td>
                        <td>
                          <div
                            className={`${
                              res?.isActive === 1 ? "active" : "pending"
                            }`}
                          >
                            <p>
                              {" "}
                              {res?.isActive === 1 ? "Active" : "Inactive"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="action">
                            <img
                              src={
                                require("../Assets/Images/delete-icon.svg")
                                  .default
                              }
                              onClick={() => {
                                setshowd(true);
                                setUserid(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            {/* <Link to={`/user-overview/${res?._id}`}> */}
                            <img
                              src={
                                require("../Assets/Images/view-icon.svg")
                                  .default
                              }
                            />
                            {/* </Link> */}
                            <OverlayTrigger
                              trigger="click"
                              show={activePopoverId === res._id}
                              placement="bottom"
                              overlay={popover(res?._id)}
                              onToggle={() => handleTogglePopover(res._id)}
                              rootClose
                            >
                              <img
                                src={
                                  require("../Assets/Images/options.svg")
                                    .default
                                }
                                alt="Options"
                                style={{ cursor: "pointer" }}
                              />
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                <p>
                    Showing {1 + (currentPage - 1) * limit} -
                    {Math.min(currentPage * limit, alldata?.data?.totalCount)}{" "}
                    of {alldata?.data?.totalCount} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={alldata?.data?.totalCount}
                    limit={alldata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p> © 2024, Copyright 2024</p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this user?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDeleteUSer}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowd(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
