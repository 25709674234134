import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import * as yup from "yup";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Countrycode from '../../src/CountryCode.json'
import {
  Editadminprofile,
  chnagePassword,
  getAdminprofile,
} from "../redux/Actions/AdminActions";
import { ProfileContext } from "../context/ProfileContext";

const validationSchema = yup.object().shape({
  old_password: yup.string().required("Required"),
  new_password: yup
    .string()
    .min(8, "New password must be at least 8 characters long")
    .matches(
      /[a-z]/,

      "Must contain at least one lowercase letter"
    )
    .matches(
      /[A-Z]/,

      "  Must contain at least one uppercase letter"
    )
    .matches(/[0-9]/, " Must contain at least one number")
    .matches(
      /[\W_]/,

      "Must contain at least one special character"
    )
    .required("Required"),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("new_password"), null], "Passwords must match")
    .required("Required"),
});

// validation schema for admin edit details

const validationschemaforEditdetails = yup.object({
  first_name: yup.string().required("Required"),
  last_name: yup.string().required("Required"),
   image: yup.string().required("Required"),
  email: yup.string().email("Invalid email address").required("Required"),

  phone_number: yup
    .string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
});

export default function MyProfile() {

  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [admindetails, setAdmindetails] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {profileData , setProfileData} = useContext(ProfileContext);

  console.log("sdfsdfsdf",setProfileData)

  const initialvalues = {
    old_password: "",
    new_password: "",
    confirmpassword: "",
  };

  const handleChangepassword = (values, resetForm) => {
    dispatch(
      chnagePassword({
        oldPassword: values?.old_password,
        newPassword: values?.new_password,
      })
    )
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);

          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/";
          }, 1500);

          resetForm();
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const token = sessionStorage.getItem("token");


  useEffect(() => {
    dispatch(getAdminprofile({ token: token }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.status === 200) {
          setAdmindetails(response?.payload?.data?.user);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const initialvaluesEditdetails = {
    first_name: admindetails?.first_name || "",
    last_name: "",
    email: "",
    country_code: "",
    phone_number: "",
    image: null,
  };

  const [formInitialValues, setFormInitialValues] = useState(
    initialvaluesEditdetails
  );
  console.log(formInitialValues);

  useEffect(() => {
    if (admindetails) {
      setFormInitialValues({
        first_name: admindetails.first_name || "",
        last_name: admindetails.last_name || "",
        email: admindetails.email || "",
        phone_number: admindetails.phone_number || "",
        country_code: admindetails.country_code || "",
        image: admindetails.profile_image || "",
      });
    }
  }, [admindetails]);

  const handleProfileedit = (values, resetForm) => {
    const formData = new FormData();
    formData.append("first_name", values.first_name);
    formData.append("last_name", values.last_name);
    formData.append("email", values.email);
    formData.append("country_code", values.country_code);
    formData.append("phone_number", values.phone_number);
    console.log(values.image, "sdfsdfsdfsdf");
    if (values.image) {
      formData.append("profile_image", values.image);
    }

    dispatch(Editadminprofile(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
         
          setAdmindetails((prevState) => ({
            ...prevState,
            ...res.payload.data.data,
          }));
          toast.success(res?.payload?.data?.message);
          resetForm();
          setProfileData(!profileData)

          dispatch(getAdminprofile({ token: token }))
          .then((response) => {
            console.log(response, "admin data");
            if (response?.payload?.status === 200) {
              setAdmindetails(response?.payload?.data?.user);
            }
          })
          .catch((error) => {
            console.log(error, "Error occur");
          });
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err, "err occur");
      });
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      // setCurruntImage("");
      setFieldValue("image", file);
      const url = URL.createObjectURL(file);
      setImagePreview(url);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="my-profile-page mt-4">
          <Formik
            enableReinitialize={true}
            initialValues={formInitialValues}
            validationSchema={validationschemaforEditdetails}
            onSubmit={(values, { resetForm }) =>
              handleProfileedit(values, resetForm)
            }
          >
            {({
              values,
              handleChange,
              handleSubmit,
              resetForm,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="profile-top-box">
                  <div className="photo-upload">
                    <div className="profile-picture">
                    {values?.image instanceof Blob ? (
                        <img
                          src={URL.createObjectURL(values?.image)}
                        />
                      ) : (
                        <img src={`${url}${admindetails?.profile_image}`} />
                      )}
                    </div>
                    <div className="upload-box">
                      <div className="btn-up">
                      <input
                          id="profileImage"
                          name="profile_image"
                          type="file"
                          accept=".jpg, .jpeg, .png, .gif"
                          onChange={(event) =>
                            handleFileChange(event, setFieldValue)
                          }
                          style={{ display: "none" }}
                        />
                        <ErrorMessage name="image" component="div"/>
                         <button
                          type="button"
                          onClick={() =>
                            document.getElementById("profileImage").click()
                          }
                          className="btn-upload"
                        >
                          Upload New Photo
                        </button>
                        <div className="reset-box">
                          <p onClick={() => resetForm()}
                            style={{ cursor: "pointer" }}>Reset</p>
                        </div>
                      </div>
                      <p className="image-disp">
                        Allowed JPG, GIF or PNG. Max size of 800K
                      </p>
                    </div>
                  </div>

                  <div className="profile-disp mt-5">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="type" placeholder="First Name"  name="first_name"
                          value={values?.first_name}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="first_name"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="type" placeholder="Last Name"  name="last_name"
                          value={values?.last_name}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="last_name"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Email" name="email"
                          value={values?.email}
                          onChange={handleChange}
                          readOnly
                        />
                        <ErrorMessage
                          name="email"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPhone"
                      >
                        <Form.Label>Phone Number</Form.Label>
                        <div className="d-flex">
                          <Form.Select
                            className="me-2"
                            style={{ maxWidth: "120px" }}
                            name="country_code"
                            value={values?.country_code}
                            onChange={(e) =>
                              setFieldValue("country_code", e.target.value)
                            }
                          >
                            {Countrycode?.map(({ dial_code, name, flag }) => (
                              <option key={dial_code} value={dial_code}>
                                {flag} {dial_code}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            type="tel"
                            placeholder="Phone Number"
                            name="phone_number"
                            value={values?.phone_number}
                            onChange={handleChange}
                          />
                        </div>
                        <ErrorMessage
                          className="error-message"
                          name="phone_number"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="submit">Save Changes</button>
                    <div className="reset-box">
                      <p style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/user");
                        }}>Cancel</p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>

          <Formik
            initialValues={initialvalues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleChangepassword(values, resetForm)
            }
          >
            {({ values, handleChange, handleSubmit, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="profile-top-box mt-4">
                  <div className="heading-profile">
                    <h2>Change Password</h2>
                  </div>
                  <div className="profile-disp mt-4">
                    <div className="form-set input-profile">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Current Password"
                          name="old_password"
                          value={values?.old_password}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="old_password"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>

                    <div className="form-set input-profile mt-2">
                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="New Password"
                          name="new_password"
                          value={values?.new_password}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="new_password"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 input-field-width"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Confirm New Password"
                          name="confirmpassword"
                          value={values?.confirmpassword}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="confirmpassword"
                          className="error-message"
                          component="div"
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="password-req mt-3 mb-4">
                    <h4>Password Requirements: </h4>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      Minimum 8 characters long - the more, the better
                    </p>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      At least one lowercase character
                    </p>
                    <p>
                      <span>
                        <img
                          src={require("../Assets/Images/bullets.svg").default}
                        />
                      </span>
                      At least one number, symbol, or whitespace character
                    </p>
                  </div>

                  <div className="btn-up mt-2">
                    <button type="submit">Save Changes</button>
                    <div className="reset-box">
                      <p
                        onClick={() => resetForm()}
                        style={{ cursor: "pointer" }}
                      >
                        Reset
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p> © 2024, Copyright 2024</p>
          </div>
          {/* <div className="bottom-links">
            <Link to="/">License</Link>
            <Link to="/">More Themes</Link>
            <Link to="/">Documentation</Link>
            <Link to="/">Support</Link>
          </div> */}
        </div>
      </Container>
    </Layout>
  );
}
