import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import UnderMaintenance from "./Pages/UnderMaintenance";
import OtpVarification from "./Pages/OtpVarification";
import MyProfile from "./Pages/MyProfile";
import User from "./Pages/User";
import UserOverview from "./Pages/UserOverview";
import ProtectedRoutes from "../src/Components/Layout/ProtectedRoutes"
import { ProfileProvider } from "./context/ProfileContext";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ProfileProvider>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/forgot-password" element={<ForgotPassword />}></Route>
          <Route path="/reset-password/:token" element={<ResetPassword />}></Route>
      
      <Route element={<ProtectedRoutes/>}>
          <Route
            path="/under-maintenance"
            element={<UnderMaintenance />}
          ></Route>

          <Route path="/otp-varification" element={<OtpVarification />}></Route>
          <Route path="/user" element={<User />}></Route>
          <Route path="/my-profile" element={<MyProfile />}></Route>
          <Route path="/user-overview/:id" element={<UserOverview />}></Route>
             
      </Route>
        </Routes>
        </ProfileProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
