import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Sidebar({ isActive, setIsActive, setShow, show }) {
  return (
    <aside>
      <div className="sidebar">
        <div className="logo-side">
          <Link to="/user">
            <img
              className="big"
              src={require("../../Assets/Images/side-bar-logo.svg").default}
            />
          </Link>
        </div>
        <div className="side-menu">
          <div>
            <div className="side-pages">
              <p>PAGES</p>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2.5"
                    y="10"
                    width="5"
                    height="6.66667"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="7.5"
                    y="6.66675"
                    width="5"
                    height="10"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="12.5"
                    y="3.33325"
                    width="5"
                    height="13.3333"
                    rx="1"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.3335 16.6667H15.0002"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Dashboard</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/user"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/user"
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    cx="11.0002"
                    cy="6.41667"
                    rx="3.66667"
                    ry="3.66667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p> Users</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <rect
                    x="3.6665"
                    y="11.9167"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <rect
                    x="14.6665"
                    y="11.9167"
                    width="3.66667"
                    height="5.5"
                    rx="1.83333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.6665 13.7501V11.0001C3.6665 6.94999 6.94975 3.66675 10.9998 3.66675C15.0499 3.66675 18.3332 6.94999 18.3332 11.0001V13.7501"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 17.4167C16.5 18.9355 14.0376 20.1667 11 20.1667"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Peeks</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11 5.5L14.6667 11L19.25 7.33333L17.4167 16.5H4.58333L2.75 7.33333L7.33333 11L11 5.5Z"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Subscription</p>
              </Link>
            </div>

            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <circle
                    cx="11"
                    cy="11"
                    r="8.25"
                    stroke="#444151"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.7113 8.93758C12.4858 8.49758 12.0623 8.23266 11.6113 8.25008H10.3884C9.71375 8.25008 9.1665 8.86424 9.1665 9.62508C9.1665 10.3832 9.71375 10.9992 10.3884 10.9992H11.6113C12.2859 10.9992 12.8332 11.6142 12.8332 12.3742C12.8332 13.1332 12.2859 13.7482 11.6113 13.7482H10.3884C9.93742 13.7657 9.51392 13.5007 9.28842 13.0607"
                    stroke="#444151"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.9998 6.41675V8.25008"
                    stroke="#444151"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.9998 13.75V15.5833"
                    stroke="#444151"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Earnings</p>
              </Link>
            </div>
            <div className="side-btm-space">
              <Link
                className={
                  window.location.pathname == "/under-maintenance"
                    ? "menu-btn bar_active"
                    : "menu-btn "
                }
                to="/under-maintenance"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M9.1665 4.58333C9.1665 3.57081 9.98732 2.75 10.9998 2.75C12.0124 2.75 12.8332 3.57081 12.8332 4.58333C14.9783 5.59767 16.3886 7.71306 16.4998 10.0833V12.8333C16.6397 13.9891 17.3201 15.0097 18.3332 15.5833H3.6665C4.67953 15.0097 5.35994 13.9891 5.49984 12.8333V10.0833C5.61109 7.71306 7.02135 5.59767 9.1665 4.58333"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.25 15.5833V16.4999C8.25 18.0187 9.48122 19.2499 11 19.2499C12.5188 19.2499 13.75 18.0187 13.75 16.4999V15.5833"
                    stroke="#2F2B3D"
                    stroke-opacity="0.9"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <p>Notifications</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
}
