import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Button }  from "react-bootstrap"

import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { login } from "../../src/redux/Actions/AdminAuth"


const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
  password: Yup.string()
    .min(6, <span className="error-message">Password too short</span>)
    .required(<span className="error-message">Required</span>),
});

export default function Login() {
  const dispatch = useDispatch();

  const handleLogin = (values) => {
   dispatch(login(values)).then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          sessionStorage.setItem('token',res?.payload?.data?.data?.token);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/user";
          }, 1500);
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="left-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Welcome to Peek! 👋🏻</h2>
                  <p>Please sign-in to your account and start the adventure</p>

                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={LoginSchema}
                    onSubmit={handleLogin}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="form-set">
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter your email "
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="email" component="div" />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 pass-eys"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          className="password-input"
                          type="password"
                          placeholder="Password"
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="password" component="div"/>
                        {/* <img
                          src={require("../Assets/Images/eye.svg").default}
                        /> */}
                      </Form.Group>
                    </div>
                    <div className="pass-rember-line">
                      {/* <div className="remember-check">
                        <input type="checkbox" class="red" id="filled-in-box" />
                        <Form.Label>Remember Me</Form.Label>
                      </div> */}
                      <Link to="/forgot-password" className="forgot">
                        Forgot Password?
                      </Link>
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      className="submit"
                    >
                      Login
                    </Button>
                  </Form>
                  )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
