import React, { createContext, useState } from "react";

// Create ProfileContext
export const ProfileContext = createContext();

// ProfileProvider component
export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(false);

  return (
    <ProfileContext.Provider value={{ profileData, setProfileData }}>
      {children}
    </ProfileContext.Provider>
  );
};
