import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form"; // Make sure you have react-bootstrap installed

const SearchBar = ({ setSearchValue }) => {
  const [searchText, setSearchText] = useState("");
  const timeout = 600; // Example timeout value, adjust as needed

  useEffect(() => {
    const handler = setTimeout(() => {
      // Add the function you want to run after the timeout here
      console.log("Searching for:", searchText);
      setSearchValue(searchText);
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]); // Add searchText as a dependency

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div >
      <Form.Control
        type="search"
        placeholder="Search"
        className="form-control w-100"
        value={searchText}
        onChange={handleSearchChange}
      />

    </div>
  );
};

export default SearchBar;
