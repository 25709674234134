import { AdminApi } from "../../Services/AdminApi";
import { createAsyncThunk } from "@reduxjs/toolkit";


// ========= Forgot Password =========

export const forgetPass = createAsyncThunk(
    "forgetPass",
    async(details) => {
        const data = await AdminApi.post(`/forgetPassword`,details);
       return data;
    } 
  );

  // ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
    "ResetPass",
    async(details) => {
        const data = await AdminApi.post(`/resetPassword`,details);
       return data;
    } 
  );


  // ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
    "chnagePassword",
    async (details) => {
      const data = await AdminApi.post(`/changepassword`, details);
      console.log(data);
      return data;
    }
  );


    // ===== Get Admin Profile ==========


export const  getAdminprofile = createAsyncThunk(
  "getAdminprofile",
  async(details)=> {
     const {data} = await AdminApi.get(`/getAdminById`, details)
     return data;
  }
);


// ========= get user list ============

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    if (details.type) {
      url += `&type=${details.type}`;
    }
    const data = await AdminApi.get(url);
    console.log(data);
    return data;
  }
);

  // =======  Dowanload Users List ============

  export const DowanloadUserxlsv = createAsyncThunk(
    "DowanloadUserxlsv",
    async () => {
      const data = await AdminApi.get(`/userManagement`);
      console.log(data);
      return data;
    }
  );


// ====== Edit admin profile ==========

export const Editadminprofile = createAsyncThunk(
  "Editadminprofile",
  async (details) => {
    const data = await AdminApi.put(`/editAdminProfile`, details);
    console.log(data);
    return data;
  }
);

  // ======== Delete User account api =============

  export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async(details) => {
        const data = await AdminApi.delete(`/deleteUser/${details?.id}`);
       return data;
    } 
  );

  // ========= get user profile by id ==========


  export const getuserprofile = createAsyncThunk(
    "getuserprofile",
    async (details) => {
      const data = await AdminApi.get(`/getUserById/${details?.id}`);
      console.log(data);
      return data;
    }
  );


       // ======== magage User account api =============

       export const maangeuseraction = createAsyncThunk(
        "maangeuseraction",
        async(details) => {
            const data = await AdminApi.post(`/banUnbanUser`, details);
           return data;
        } 
      );

  