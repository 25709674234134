import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useDispatch } from "react-redux";
import { getuserprofile, usermanagement } from "../redux/Actions/AdminActions";
import Logo from "../Assets/Images/Placeholder.png";

export default function UserOverview() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [Userdetails, setuserDetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showd, setshowd] = useState(false);
  const [show, setshow] = useState(false);
  const [flag, setflag] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ id: id }))
      .then((response) => {
        console.log(response, "admin data");
        if (response?.payload?.data?.status === 200) {
          setuserDetails(response?.payload?.data?.data?.user);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3"></div>
      </Container>

      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={
                      Userdetails?.profile_image
                        ? `${url}${Userdetails?.profile_image}`
                        : Logo
                    }
                  />
                  <h2>{Userdetails?.name || "N/A"}</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Email : <span>{Userdetails?.email || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Phone no :{" "}
                          <span>
                            {Userdetails?.countryCode
                              ? `${Userdetails?.countryCode}  ${Userdetails?.phoneNumber}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Height : <span>{Userdetails?.height  || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Date of birth : <span>{Userdetails?.dob || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Gender : <span> Female</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Occupation : <span>Student</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Interest :
                           <span>
                            {Userdetails?.interest?.map((res) => (
                              res === 1 ? "travling" : "N/A"
                            ))}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Bio :
                           <span>
                           {Userdetails?.about  || "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className="bottom-btn">
                    <button>Delete</button>
                    <button className="block-btn">Suspend</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="middle-over-view">
              <div className="standard-box">
                <div className="month-sub">
                  <div className="text-standard">
                    <p>Standard</p>
                  </div>
                  <div className="monthly-num">
                    <p>
                      <span>$</span> <span>99</span> month
                    </p>
                  </div>
                </div>
                <div className="stand-disp">
                  <ul>
                    <li>Ut tincidunt interdum ullamcorper.</li>
                    <li>
                      Nam eu odio ultrices, feugiat elit vitae, finibus sapien.
                    </li>
                    <li>Nunc feugiat mauris a nisl semper.</li>
                  </ul>
                </div>
                <div className="progress-bar-custom">
                  <div className="progress-days">
                    <p>Days</p>
                    <p>26 of 30 Days</p>
                  </div>
                  <div className="custom-progress">
                    <ProgressBar now={60} />
                  </div>
                  <span>4 days remaining</span>
                </div>
                <div className="bottom-btn upgrade-plan">
                  <button>Upgrade Plan</button>
                </div>
              </div>
              <div className="standard-box mt-4">
                <div className="peeks-available">
                  <h2>100</h2>
                  <p>Peeks available</p>
                </div>

                <div className="bottom-btn upgrade-plan">
                  <button>Add Peeks</button>
                </div>
              </div>
            </div>
            <div className="last-over-view">
              <div className="photos-gall">
                <h2>Photos</h2>
                <div className="photo-grid mt-4">
                  <div className="photos">
                    <img src={require("../Assets/Images/add-photo.png")} />
                  </div>
                  <div className="photos">
                    <img src={require("../Assets/Images/add-photo.png")} />
                  </div>
                  <div className="photos">
                    <img src={require("../Assets/Images/add-photo.png")} />
                  </div>
                  <div className="photos">
                    <img src={require("../Assets/Images/add-photo.png")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>© 2024, Copyright 2024</p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
